import Alpine from 'alpinejs'
import header from '@/components/header.js'
import video from '@/components/video.js'
import embedVideo from '@/components/embed/video.js'
import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';

let dv = document.querySelector("section");
if(dv) {
    let spl = dv.classList.contains("splide");
    if (spl) {
        new Splide('.splide', {
            'arrows': false,
            'autoplay': true,
            'type'  : 'fade',
            'speed' : '800'
        }).mount();
    }
}


//
// Fonts
//
import '@fontsource/playfair-display/900.css'
import '@fontsource-variable/outfit'

//
// Styles
//
import '../css/style.css'

//
// Setup Alpine
//
window.Alpine = Alpine

//
// Setup Alpine components
//
Alpine.data('header', header)
Alpine.data('video', video)
Alpine.data('embedVideo', embedVideo)

//
// Initialize Alpine
//
Alpine.start()
