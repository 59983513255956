import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const mobileNavigationBreakpoint = 1024

export default () => ({
    isActive: false,

    onResize() {
        if (window.innerWidth > mobileNavigationBreakpoint) {
            this.isActive = false
        }
    },

    init() {
        const boundOnResize = this.onResize.bind(this)

        this.$watch('isActive', (isActive) => {
            if (isActive) {
                window.addEventListener('resize', boundOnResize)
                disableBodyScroll(this.$refs.panel, {
                    reserveScrollBarGap: true,
                })
            } else {
                window.removeEventListener('resize', boundOnResize)
                enableBodyScroll(this.$refs.panel)
            }
        })
    },
})
